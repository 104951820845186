body {
  margin: 0;
  font-family: "Lato", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navname {
  font-size: 16px;
  padding: 12px;
  line-height: 1;
}

.navicon {
  min-width: 56px;
}

.rbt-input-multi .rbt-input-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -4px;
  margin-top: -1px;
  overflow: hidden;
}
.rbt-token {
  background-color: #0198a9;
  border: 0;
  border-radius: 0.25rem;
  color: #ffffff;
  display: inline-flex;
  line-height: 1rem;
  padding: 4px;
  margin: 2px 3px 2px 0;
}
.rbt-token-label {
  padding-top: 2px;
  padding-right: 4px;
}

.mobileMenuToggler {
  display: none;
}
@media screen and (max-width: 768px) {
  .mobileMenuToggler {
    display: block;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .phUserName {
    display: none;
  }
}
