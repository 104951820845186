$primary: #ffaf00;
$secondary: #1c1c1c;
$success: #49db1c;
$info: rgb(7, 200, 46);
$warning: yellow;
$danger: rgba(255, 0, 0, 0.719);
$light: #1c1c1c;
$dark: #1c1c1c;
$white: #ffff;
$body-secondary-color: rgba($secondary, 0.75) !default;
$font-family-base: "Heebo", sans-serif;
$body-bg: $light;
$body-color: #050608;
$headings-color: $light;
$headings-font-weight: 700;
$enable-responsive-font-sizes: true;

$body-bg: $white;
$body-color: $dark;

$border-radius: 5px;

$link-decoration: none;

$enable-negative-margins: true;
$table-bg: var(--light);
$input-placeholder-color: $dark;

$theme-colors: (
  "primary": $primary,
  "danger": $danger,
  "secondary": $secondary,
  "info": $info,
  "success": $success,
);

@import "~bootstrap/scss/bootstrap";

/********** Template CSS **********/
:root {
  --primary: #009cff;
  --light: #f3f6f9;
  --dark: #1c1c1c;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  transition: 0.5s;
}

.btn.btn-primary {
  color: $dark;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}

/*** Layout ***/
.sidebar {
  // this is when sidebar is opened
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
  height: 100vh;
  overflow-y: auto;
  border-right: 2px solid var(--light);
  // transition: 0.5s;
  z-index: 999;
  -ms-overflow-style: none;
  scrollbar-width: none;
  // background-color: #f3f6f9 !important;
  color: #ffffff;
}

.sidebarClose {
  // when sidebar is close i.e becomes smaller
  margin-left: 0px;
  top: 0;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 100vh;
  overflow-y: auto;
  // background: #f3f6f9;
  border-right: 2px solid var(--light);
  transition: 0.2s;
  z-index: 999;
  position: fixed;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.content {
  // when sidebar is closed
  margin-left: 200px;
  min-height: 100vh;
  background: #ffffff;
  // transition: 0.5s;
}
.content.open {
  margin-left: 60px;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebarClose::--webkit-scrollbar {
  display: none;
}

@media (max-width: 500px) {
  .sidebar {
    width: 100%;
    padding-top: 4rem;
    background-color: var(--bs-gray-300);
  }
  .sidebarClose {
    width: 0%;
  }
  .content {
    margin-left: 0px !important;
    width: 100%;
  }
  .content.open {
    width: 100%;
  }
}

/*** Navbar ***/
.sidebar .navbar .navbar-nav .nav-link {
  padding: 6px 0px 6px 0px;
  color: var(--dark);
  font-weight: 500;
  outline: none;
  margin: 1px 0px;
  border-left: 2px solid var(--light);
}
.sidebarClose .navbar .navbar-nav .nav-link {
  color: $primary;
}
.sidebarClose .navbar .navbar-nav .nav-link {
  width: 100%;
  text-align-last: center;
  border-left: 2px solid rgba(255, 255, 255, 0);
}
.sidebarClose .navbar .navbar-nav .nav-link:hover {
  color: $primary;
  background-color: #f3f6f9;
  border-left: 2px solid $info;
}
.sidebarClose .navbar .navbar-nav .nav-link:active {
  background-color: #f3f6f9;
  color: white;
  border-left: 2px solid $info;
}
.sidebar .navbar .navbar-nav .nav-link:hover {
  background: #f3f6f9;
  border-left: 2px solid $info;
  color: $primary;
}
.sidebar .navbar .navbar-nav .nav-link.active {
  color: var(--danger);
  background: #dbdfe2;
  border-color: $danger;
  border-left: 2px solid $info;
}
.sidebar .navbar .navbar-nav .nav-link i {
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}
.sidebar .navbar .dropdown-toggle::after {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transition: 0.5s;
}

.sidebar .navbar .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}

.sidebar .navbar .dropdown-item {
  padding-left: 25px;
  border-radius: 0 30px 30px 0;
}

.content .navbar .navbar-nav .nav-link {
  margin-left: 25px;
  padding: 12px 0;
  color: var(--dark);
  outline: none;
}

.content .navbar .navbar-nav .nav-link:hover,
.content .navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.content .navbar .sidebar-toggler,
.content .navbar .navbar-nav .nav-link i {
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 40px;
}

.content .navbar .dropdown-toggle::after {
  margin-left: 6px;
  vertical-align: middle;
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transition: 0.5s;
}

.content .navbar .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}

@media (max-width: 575.98px) {
  .content .navbar .navbar-nav .nav-link {
    margin-left: 15px;
  }
}

/*** Date Picker ***/
.bootstrap-datetimepicker-widget.bottom {
  top: auto !important;
}

.bootstrap-datetimepicker-widget .table * {
  border-bottom-width: 0px;
}

.bootstrap-datetimepicker-widget .table th {
  font-weight: 500;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 10px;
  border-radius: 2px;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background: var(--primary);
}

.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: var(--primary);
}

/*** Testimonial ***/
.progress .progress-bar {
  width: 0px;
  transition: 2s;
}

/*** Testimonial ***/
.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 5px solid var(--primary);
  border-radius: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--dark);
  border-color: var(--primary);
}

.bg-light {
  background-color: #f3f6f9 !important;
}

.bg-success {
  background-color: #198754 !important;
}

.form-control {
  background-color: #ffff;
}

.form-control:focus {
  color: black;
  background-color: #ffff;
}

img {
  object-fit: cover;
}

a.pointer {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.icon-gold {
  color: goldenrod;
}

@media (max-width: 978px) {
  .container-fluid {
    padding: 0 !important;
    margin: 0 !important;
  }

  .hidden-sm {
    display: none;
  }
}

@media (max-width: 425px) {
  .pagination {
    text-align: center;
    display: block;
  }
}

th {
  color: #687182;
  font-weight: 700;
}

thead {
  background: var(--light) !important;
}

tr {
  border-bottom: 1px solid #f1f3f5;
}

// tabs design
.nav {
  color: #050608;
}

.nav-tabs {
  border: none;
  border-bottom: none;
}

.nav-tabs {
  border-bottom: none !important;
}

.nav-tabs .nav-link {
  border: 1px solid transparent !important;
  color: #050608;
}

.nav-tabs .nav-link.active {
  border: none;
  border-bottom: 4px solid $primary !important;
  color: $primary;
}
.modal {
  right: 0 !important;
  bottom: 0 !important;
}

.model-dialog {
  // margin-right: 10px !important;
  transform: none !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    margin-right: 10px !important;
  }
  #switchCompanyModel {
    margin-right: auto !important;
  }
}

.modal-dialog-centered {
  min-height: calc(80% - var(--bs-modal-margin) * 2);
}
.modal-content {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border: none !important;
  position: absolute;
  top: 70px;
  right: -508px;
}

@media (max-width: 500px) {
  .modal-content {
    right: -515px;
  }
}

.modal.show .modal-dialog {
  transform: translateX(-515px);
  transition: 0.5s ease-in;
}
// scrollbar changes
body::-webkit-scrollbar {
  width: 11px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--light);
}
body::-webkit-scrollbar-track {
  background: var(--light);
}
body::-webkit-scrollbar-thumb {
  background-color: $secondary;
  border-radius: 6px;
  border: 2px solid var(--light);
}

// dropdown
.dropdown-item {
  color: $dark;
}
// .dropdown-item:hover {
//   background-color: transparent !important;
// }

.loyaltyCardModel .modal-content {
  width: 180%;
}
.loyaltyCardImage {
  width: auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
}
.slotImage {
  height: 50px;
  width: 50px;
  position: relative;
  background-color: $secondary;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $light;
  margin-top: 0.5rem !important;
  overflow: hidden;
  border: 1px solid white;
}

.slotImage img {
  height: 50px;
  width: 50px;
  position: relative;
  object-fit: cover;
}

.loyaltyCardImage p {
  color: #808080;
  font-family: "Carme", sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 0px;
}
.loyaltyCardImage h5 {
  color: #ffff;
  // font-family: "Rouge Script", cursive;
  font-weight: normal;
  text-align: center;
  text-shadow: 1px 1px 2px #082b34;
}
.switchCompanyList {
  background-color: var(--light);
  border-radius: 10px;
  cursor: pointer;
}
.switchCompanyList:hover {
  background-color: #e3e8ed;
  border-radius: 10px;
  cursor: pointer;
}

.form-select {
  background-color: #fff !important;
}

.loyaltyCardHoverContainer {
  position: relative;
  width: 100%;
}

.loyaltyCardHoverContainer {
  .loyaltyCard {
    opacity: 1;
    display: flex;
    width: 100%;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .text {
    background-color: #000000;
    color: white;
    font-size: 16px;
    padding: 12px 16px;
    border-radius: 12px;
  }
}

.loyaltyCardHoverContainer:hover .loyaltyCard {
  opacity: 0.3;
}

.loyaltyCardHoverContainer:hover .middle {
  opacity: 1;
}

.stampLoyaltyCardModel .modal-content {
  width: 100%;
}

.stamp-count-text {
  font-size: 20px;
}

.loyalty-hover:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.container-min-width {
  min-width: 100%;
}

.loginLeftContainer {
  min-height: 100vh;
  align-items: center;
  padding: 4px;
  display: flex;
}

.loginRightContainer {
  min-height: 100vh;
  align-items: center;
  display: flex;
  padding: 4px;
}

@media (max-width: 978px) {
  .loginLeftContainer {
    margin-top: 28px;
    min-height: 10vh;
  }

  .loginRightContainer {
    min-height: 36vh;
  }
}

.text-limit-two-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.text-limit-one-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1; 
  -webkit-box-orient: vertical;
}

